<script context="module">
	import { base } from '$app/paths';

	export async function load({ fetch }) {
		const r = await fetch(`${base}/patterns.json`);
		const patterns = await r.json();
		
		return {
			props: { patterns }
		};
	}
</script>

<script type="ts">
	import Bottom from '$theme/Bottom.svelte';
	import Top from '$theme/Top.svelte';

	export let patterns;

	let coming_soon = [
		{
			shortname: 'The Decorator Pattern',
			summary: ''
		},
		{
			shortname: 'The Factory Pattern',
			summary: ''
		},
		{
			shortname: 'The Singleton Pattern',
			summary: ''
		},
		{
			shortname: 'The Command Pattern',
			summary: ''
		},
		{
			shortname: 'The Template Method',
			summary: ''
		},
		{
			shortname: 'And more to come',
			summary: 'This is a coming soon page.'
		}
	];
</script>

<svelte:head>
	<meta name="title" content="On Writing Code" />
	<!-- <meta name="description" content="" /> -->
</svelte:head>

<Top />
<div class="grid">
	{#each patterns as { shortname, path, summary }}
		<a class="entry" sveltekit:prefetch href={path.replace(/\.[^/.]+$/, '')}>
			<h2>The {shortname} Pattern</h2>
			<p>{summary}</p>

			<div class="read-more">
				<span>Read More</span>
				<span class="arrow">&rarr;</span>
			</div>
		</a>
	{/each}

	{#each coming_soon as { shortname, summary }}
		<div class="entry coming-soon">
			<h2>{shortname}</h2>
			<div class="read-more">
				<span>Coming Soon</span>
			</div>
		</div>
	{/each}
</div>
<Bottom />

<style lang="scss">
	h2 {
		text-align: left;
		padding: 0;
		font-family: var(--font-heading);
		font-weight: 400;
		font-size: clamp(1.495rem, 1.375vw, 1.99rem);
	}
	a {
		color: var(--text-color-accent);
		text-decoration: none;
		&:hover {
			color: var(--text-bold);
		}
	}
	p {
		font-size: 1rem;
		margin-bottom: 1rem;

		padding-top: 10px;
		margin-top: 10px;
	}

	.read-more {
		display: flex;
		gap: 10px;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 0.7em;
	}

	.grid {
		padding-top: 80px;
		padding-bottom: 40px;
		row-gap: 80px
	}

	.entry {

	}
	.coming-soon {
		color: var( --text-color-dampest );
		.read-more {
			font-style: italic;
			font-weight: 400;
		}
	}
</style>
